<template>
  <TransitionWrapper>
    <div class="billing">
      <div class="column">
        <AddFundsButton
          v-if="isLoaded"
          :is-checked="isViewBalance"
          @click="isViewBalance = !isViewBalance"
          @keypress.space="isViewBalance = !isViewBalance"
        />

        <transition name="fade-absolute">
          <BalanceInfo
            v-if="isViewBalance"
            :is-loaded="isLoaded"
            :billing-info="billingInfo"
            :active-subscriptions="activeSubscriptions"
            :payments-stats="paymentsStats"
          />
          <AddFunds v-else :deposit-addresses="billingInfo.depositAddresses" />
        </transition>
      </div>

      <BillingHistory />
    </div>
  </TransitionWrapper>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import dayjs from 'dayjs';

import TransitionWrapper from '@/components/Animation/TransitionWrapper.vue';

import { getBillingInfo, getMerchantProfile } from '@/api/merchant';
import { useBus } from '@/composables/useBus';
import {
  getDepositOperations,
  getInvoices,
  getMerchantStats,
} from '@/api/billing';

import AddFundsButton from './AddFundsButton.vue';
import AddFunds from './AddFunds';
import BalanceInfo from './BalanceInfo';
import BillingHistory from './BillingHistory';

const billingInfo = ref({});

const isLoaded = ref(false);
const isViewBalance = ref(true);

const activeSubscriptions = ref(0);
const paymentsStats = ref({});

onBeforeMount(() => {
  getData();
  getHistoryData({ year: dayjs().year(), isFreshResponse: true });
});

const getStats = async () => {
  const { isSuccess, data } = await getMerchantStats({ isFreshResponse: true });

  if (isSuccess) {
    activeSubscriptions.value = data.subscriptions.active;
    paymentsStats.value = data.payments;
    isLoaded.value = true;
  }
};

const getData = async () => {
  getMerchantProfile({ isFreshResponse: true });
  getStats();
  const { data } = await getBillingInfo();
  billingInfo.value = data;
};

const { emitHandler } = useBus();

emitHandler('update-billing-page', () => {
  getData();
  getHistoryData({ year: dayjs().year(), isFreshResponse: true });
});

const getHistoryData = async ({ year, isFreshResponse = false }) => {
  getDepositOperations({ year, isFreshResponse });
  getInvoices({ year, isFreshResponse });
};
</script>

<style lang="scss" scoped>
.billing {
  overflow: hidden;
  max-width: $contentMaxWidth;
  width: calc(100% - 230px);
  height: calc(100% + 50px);
  display: grid;
  grid-template-columns: 360px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  position: fixed;

  .column {
    position: relative;
    background: #F7F7F7;
    border-right: 1px solid var(--color-black-01);
    width: 360px;

    :deep(.add-funds-btn) {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 10;
    }
  }
}
</style>
