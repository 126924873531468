<template>
  <div class="balance-history-years">
    <div
      v-for="year in yearsForShow"
      :key="year"
      class="year"
      tabindex="0"
      :class="{ active: year === selectedYear }"
      @click="setYear(year)"
      @keypress.space="setYear(year)"
    >
      <AppText>
        {{ year }}
      </AppText>
    </div>

    <FDropdown
      v-if="yearsList.length > 3"
      placement="bottom-start"
    >
      <template #title>
        <div class="title">
          <AppIcon name="dots-menu" size="18px" />
        </div>
      </template>

      <template #content>
        <div class="dropdown-years">
          <DropdownItem
            v-for="year in yearsForDropdown"
            :key="year"
            @click="setYear(year)"
          >
            <AppText>
              {{ year }}
            </AppText>
          </DropdownItem>
        </div>
      </template>
    </FDropdown>

    <div
      v-if="!yearsForShow.includes(selectedYear)"
      class="year active"
    >
      <AppText>
        {{ selectedYear }}
      </AppText>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

import DropdownItem from '@/components/ElementUI/DropdownItem.vue';

import { getDepositOperations, getInvoices } from '@/api/billing';

const props = defineProps({
  startYear: {
    type: Number,
    default: () => new Date().getFullYear(),
  },
});

const selectedYear = ref(new Date().getFullYear());

const yearsList = computed(() => {
  const years = [];

  for (let year = props.startYear; year <= new Date().getFullYear(); year += 1) {
    years.push(year);
  }
  return years.reverse();
});

const yearsForShow = computed(() => yearsList.value.slice(0, 3));
const yearsForDropdown = computed(() => yearsList.value.slice(3, yearsList.value.length));

const setYear = (year) => {
  if (selectedYear.value !== year) {
    selectedYear.value = year;
    getHistoryData({ year, isFreshResponse: true });
  }
};

const getHistoryData = async ({ year, isFreshResponse = false }) => {
  getDepositOperations({ year, isFreshResponse });
  getInvoices({ year, isFreshResponse });
};
</script>

<style lang="scss" scoped>
:deep(.f-dropdown) {
  box-sizing: border-box;
  width: 36px !important;
  height: 36px !important;
  border: 1px solid var(--color-black-01);

  &:hover {
    background: #F7F7F7;
  }

  .f-dropdown--title {
    padding: 0;

    .title {
      box-sizing: border-box;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.dropdown-years {
  width: 100%;

  .year {
    cursor: pointer;
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid var(--color-black-01);

    @include focus-visible;

    &:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0);
    }

    &:hover {
      background: #F5F5F5;
    }
  }
}

.balance-history-years {
  display: flex;
  gap: 8px;

  .year {
    box-sizing: border-box;
    cursor: pointer;
    height: 36px;
    padding: 0 14px;
    background: var(--color-white);
    border: 1px solid var(--color-black-01);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include transition-base('background, color, border');

    &.active {
      background: var(--color-black-005);
    }

    &:hover {
      background: var(--color-black-008);
    }
  }
}
</style>
