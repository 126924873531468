<template>
  <div class="history">
    <AppText
      variant="div"
      size="20px"
      :line-height="1.35"
      class="font-medium"
      style="margin: 0 2px 16px;"
    >
      {{ t('merchantBilling.history.title') }}
    </AppText>

    <transition name="show">
      <BalanceHistory
        v-if="historyStartedYear !== null"
        :deposits="deposits"
        :invoices="invoices"
        :start-year="historyStartedYear"
      />

      <NoHistory
        v-else
        :title="t('merchantBilling.history.noHistory.title')"
        :description="t('merchantBilling.history.noHistory.description')"
      />
    </transition>
  </div>
</template>

<script setup>
import { useBillingHistory } from '@/composables/useBillingHistory';

import BalanceHistory from './BalanceHistory';
import NoHistory from './NoHistory.vue';

const {
  deposits,
  invoices,
  historyStartedYear,
} = useBillingHistory();
</script>

<style lang="scss" scoped>
.history {
  padding: 44px 30px 0 40px;

  :deep(.transactions) {
    margin-top: 16px;
  }
}
</style>
