<template>
  <div class="balance-history">
    <div class="header">
      <BalanceHistoryYears :start-year="props.startYear" />
      <BalanceHistoryFilters v-model="filter" />
    </div>

    <CustomScroll class="body">
      <transition v-if="isHistoryLoaded" name="show">
        <AllHistory
          v-if="isDepositsShow && isInvoicesShow"
          :deposits="deposits"
          :invoices="invoices"
        />
      </transition>
      <transition v-if="isHistoryLoaded" name="show">
        <div v-if="isDepositsShow && !isInvoicesShow">
          <DepositHistory
            v-for="deposit in deposits"
            :key="deposit._id"
            :deposit="deposit"
            class="history-item"
          />
        </div>
      </transition>
      <transition v-if="isHistoryLoaded" name="show">
        <div v-if="isInvoicesShow && !isDepositsShow">
          <InvoiceHistory
            v-for="invoice in invoices"
            :key="invoice._id"
            :invoice="invoice"
            class="history-item"
          />
        </div>
      </transition>

      <transition name="show">
        <NoData v-if="isNoHistory" style="padding-top: 88px;" />
      </transition>
    </CustomScroll>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

import NoData from '@/components/Modules/AgGrid/NoData.vue';

import { useBillingHistory } from '@/composables/useBillingHistory';

import { filtersList } from './data';
import DepositHistory from './DepositHistory.vue';
import InvoiceHistory from './InvoiceHistory.vue';
import AllHistory from './AllHistory.vue';
import BalanceHistoryFilters from './BalanceHistoryFilters.vue';
import BalanceHistoryYears from './BalanceHistoryYears.vue';

const props = defineProps({
  deposits: {
    type: Array,
    default: () => [],
  },
  invoices: {
    type: Array,
    default: () => [],
  },
  startYear: {
    type: Number,
    default: () => new Date().getFullYear(),
  },
});

const { isHistoryLoaded } = useBillingHistory();

const filter = ref(filtersList.ALL);

const isDepositsShow = computed(() => [filtersList.ALL, filtersList.DEPOSITS].includes(filter.value));
const isInvoicesShow = computed(() => [filtersList.ALL, filtersList.INVOICES].includes(filter.value));

const isNoDepositHistory = computed(() => props.deposits.length === 0);
const isNoInvoiceHistory = computed(() => props.invoices.length === 0);
const isNoHistoryYear = computed(() => isNoDepositHistory.value && isNoInvoiceHistory.value);

const isNoHistory = computed(() => {
  if (isNoHistoryYear.value && filter.value === filtersList.ALL) {
    return true;
  }

  if (isNoDepositHistory.value && filter.value === filtersList.DEPOSITS) {
    return true;
  }

  if (isNoInvoiceHistory.value && filter.value === filtersList.INVOICES) {
    return true;
  }

  return false;
});
</script>

<style lang="scss" scoped>
.balance-history {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 2px;
  }

  .body {
    margin-top: 16px;
    padding-bottom: 30px;
    height: calc(100vh - 220px);

    .history-item {
      border-bottom: 1px solid var(--color-black-01);
    }
  }
}
</style>
