<template>
  <div>
    <div v-for="record in records" :key="record._id">
      <DepositHistory v-if="record.type === 'deposit'" :deposit="record" />
      <InvoiceHistory v-if="record.type === 'invoice'" :invoice="record" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import DepositHistory from './DepositHistory.vue';
import InvoiceHistory from './InvoiceHistory.vue';

export default {
  name: 'AllHistory',
  components: {
    DepositHistory,
    InvoiceHistory,
  },
  props: {
    deposits: {
      type: Array,
      default: () => [],
    },
    invoices: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const records = computed(() => {
      const resultDeposits = props.deposits.map((deposit) => {
        deposit.type = 'deposit';
        return deposit;
      });

      const resultInvoices = props.invoices.map((invoice) => {
        invoice.type = 'invoice';
        return invoice;
      });

      const result = [...resultDeposits, ...resultInvoices];

      return result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    });

    return { records };
  },
};
</script>

<style lang="scss" scoped>
</style>
