<template>
  <div class="invoice">
    <div class="invoice-icon">
      <AppIcon name="invoice-blue" size="30px" />
    </div>
    <div class="info">
      <DateFormatter
        :date="props.invoice.createdAt"
        :format="dateTimeFormat"
        variant="div"
        size="13px"
        class="font-medium"
      />
      <AppText
        variant="div"
        opacity="0.4"
        mt="2px"
      >
        {{ t('merchantBilling.label.invoice') }} {{ props.invoice.uid10Digits }}
      </AppText>
    </div>
    <CurrencyFormatter
      :value="props.invoice.amount"
      is-raw
      variant="div"
      size="15px"
      class="font-medium"
    />
    <FButton
      class="download-button"
      icon="cloud-download"
      type="transparent"
      is-icon
      :loading="isLoading"
      @click="onLoadInvoice"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';

import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';
import DateFormatter from '@/components/Helpers/DateFormatter.vue';

import { useFetch } from '@/api';
import { useProfile } from '@/composables/useProfile';
import { useUser } from '@/composables/useUser';
import { downloadAsFile } from '@/utils/functions';

const props = defineProps({
  invoice: {
    type: Object,
    required: true,
  },
});

const { uid } = useUser();
const { axiosWrapper } = useFetch();

const isLoading = ref(false);
const onLoadInvoice = async () => {
  isLoading.value = true;
  const response = await axiosWrapper({
    type: 'get',
    // eslint-disable-next-line no-underscore-dangle
    url: `/merchants/invoices/${props.invoice._id}`,
    responseType: 'blob',
  });

  if (response.result?.data) {
    downloadAsFile({
      data: response.result.data,
      filename: `${uid.value}-${props.invoice.uid10Digits}`,
      type: 'pdf',
    });
  }

  isLoading.value = false;
};

const { timeFormat } = useProfile();
const dateTimeFormat = `DD MMM YYYY ${timeFormat.value}`;
</script>

<style lang="scss" scoped>
.invoice {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 2px;
  border-bottom: 1px solid var(--color-black-01);

  .invoice-icon {
    margin-left: 10px;
    width: 30px;
  }

  .info {
    flex-grow: 1;
    margin-left: 14px;
  }
}

.download-button {
  margin: 0 10px 0 8px;
  :deep(svg path) {
    @include transition-base(fill);
  }
  &:hover {
    background: var(--color-FBF3EC);

    :deep(svg path) {
      fill: var(--color-primary);
    }
  }
}
</style>
