<template>
  <div class="balance-history-filters">
    <AppText
      v-for="filter in filtersList"
      :key="filter"
      class="filter"
      tabindex="0"
      :class="{ active: item === filter }"
      @click="item = filter"
      @keypress.space="item = filter"
    >
      {{ t(`merchantBilling.filter.${filter}`) }}
    </AppText>
  </div>
</template>

<script setup>
import { filtersList } from './data';

const item = defineModel({ type: String, default: filtersList[0] });
</script>

<style lang="scss" scoped>
.balance-history-filters {
  display: flex;
  gap: 4px;

  .filter {
    cursor: pointer;
    height: 32px;
    background: var(--color-black-004);
    border-radius: 28px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    @include transition-base('background, color');
    &:focus-visible {
      @include focus-visible-style;
      border-radius: 28px !important;
    }

    &:hover {
      background: var(--color-black-008);
    }

    &.active {
      background: var(--color-primary-01);
      color: var(--color-primary);
    }
  }
}
</style>
