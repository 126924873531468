<template>
  <DropdownBlock
    class="deposit"
    type="blank"
    @toggle="(val) => isOpen = val"
  >
    <template #header>
      <div class="deposit-info">
        <div class="deposit-icon">
          <AppIcon name="deposit-green" size="30px" />
        </div>
        <div class="info">
          <DateFormatter
            :date="deposit.createdAt"
            :format="dateTimeFormat"
            variant="div"
            size="13px"
            class="font-medium"
          />
          <AppText variant="div" opacity="0.4" mt="2px">
            {{ t('merchantBilling.label.deposit') }} <template v-if="deposit.isCredit">
              ({{ t('merchantBilling.label.credit') }})
            </template>
          </AppText>
        </div>
        <div v-if="deposit.status !== 'COMPLETE'" class="status">
          <AppIcon name="spinner" size="12px" />
          <AppText variant="div" opacity="0.4">
            {{ t('merchantBilling.label.confirming') }}
          </AppText>
        </div>
        <CurrencyFormatter
          :value="deposit.valueInUsd"
          is-raw
          variant="div"
          size="15px"
          class="font-medium"
        />
        <div class="dropdown-icon">
          <DropdownArrow :is-open="isOpen" />
        </div>
      </div>
    </template>
    <template #default>
      <div class="deposit-more">
        <div>
          <AppText variant="div" opacity="0.4" mb="8px">
            {{ deposit.isCredit ? t('merchantBilling.label.reason') : t('merchantBilling.label.hash') }}
          </AppText>

          <TooltipCopy class="payment-link" is-min-gap>
            <AppText v-if="deposit.isCredit" variant="div">
              {{ deposit.creditNote?.note }}
            </AppText>
            <AppText v-else class="hash" variant="div">
              {{ deposit.hash }}
            </AppText>
          </TooltipCopy>
        </div>

        <div v-if="!deposit.isCredit">
          <AppText variant="div" opacity="0.4">
            {{ t('common.amount') }}
          </AppText>
          <TooltipCopy is-icon-hidden>
            <AppText variant="div" mt="8px">
              {{ deposit.value }}&nbsp;{{ deposit.exchangeCurrency }}
            </AppText>
          </TooltipCopy>
        </div>
      </div>
    </template>
  </DropdownBlock>
</template>

<script>
import { ref } from 'vue';

import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';
import DateFormatter from '@/components/Helpers/DateFormatter.vue';
import DropdownArrow from '@/components/Icon/DropdownArrow.vue';
import DropdownBlock from '@/components/Containers/DropdownBlock.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';

import { useProfile } from '@/composables/useProfile';

export default {
  name: 'DepositHistory',
  components: {
    CurrencyFormatter,
    DateFormatter,
    DropdownArrow,
    TooltipCopy,
    DropdownBlock,
  },
  props: {
    deposit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const isOpen = ref(false);
    const { timeFormat } = useProfile();
    const dateTimeFormat = `DD MMM YYYY ${timeFormat.value}`;

    return { isOpen, dateTimeFormat };
  },
};
</script>

<style lang="scss" scoped>
.deposit {
  display: flex;
  flex-direction: column;
  margin: 0 2px;
  @include transition-base('background');
  border-bottom: 1px solid var(--color-black-01);

  &.active, &:hover {
    background:  var(--color-FAFAFA);
    @include transition-base('height');
  }

  .deposit-info {
    width: 100%;
    cursor: pointer;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .deposit-icon {
      margin-left: 10px;
      width: 30px;
      height: 30px;
      @include transition-base(background);

      :deep(svg path) {
        @include transition-base(fill);
      }
    }

    .info {
      flex-grow: 1;
      margin-left: 14px;
    }

    .status {
      display: flex;
      align-items: center;
      margin-right: 30px;

      :first-child {
        margin-right: 8px;
      }

      :deep(svg path) {
        opacity: 0.4;
      }
    }

    .dropdown-icon {
      width: 32px;
      height: 32px;
      margin: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      @include transition-base('background');

      :deep(svg) {
        opacity: 1;

        path {
          fill: var(--color-black);
          @include transition-base('fill');
        }
      }

      &:hover {
        cursor: pointer;
        background: var(--color-FBF3EC);
        border-radius: 4px;

        :deep(svg path) {
          fill: var(--color-primary);
        }
      }
    }
  }

  .deposit-more {
    margin: 0 28px 0 54px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--color-black-01);
    padding: 20px 22px 0 0;

    .hash {
      word-break: break-all;
    }
  }

  :deep(.dropdown-block-body.is-open) {
    height: 100px !important;
  }
}
</style>
