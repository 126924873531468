<template>
  <div class="transactions">
    <div class="icons">
      <AppIcon name="btc-circle-grey" size="40px" />
      <AppIcon name="ltc-circle-grey" size="40px" />
    </div>

    <AppText
      variant="div"
      size="16px"
      opacity="0.5"
      align="center"
      mt="20px"
    >
      {{ title }}
    </AppText>

    <AppText
      variant="div"
      opacity="0.5"
      align="center"
      mt="10px"
    >
      {{ description }}
    </AppText>
  </div>
</template>

<script>
export default {
  name: 'NoHistory',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.transactions {
  padding-top: 140px;

  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
</style>
